import { API } from 'aws-amplify';
import { Auth } from '@aws-amplify/auth';

const apiName = "AppAPI"

async function authHeaders(): Promise<{[key: string]: any}|undefined> {
    return {
        headers: {
            "Authorization": `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
}


async function get(url: string) {
    return await API.get(apiName, url, await authHeaders());
}

/**
 * Can't call it delete, cos its a reserved word. 
 */
async function callDelete(url: string) {
    return await API.get(apiName, url, await authHeaders());
}


async function post(url: string, body: {[key: string]: any}) {
    return await API.post(apiName, url, {
        ...(await authHeaders()),
        body: body
    });
}


export default {
    get,
    callDelete: callDelete,
    post
}