    import React, { Component } from 'react';
    import './App.css';
    import { Auth} from '@aws-amplify/auth';
    import { Hub } from 'aws-amplify';
    import {  Route, Switch,  Link, withRouter, Redirect, RouteComponentProps } from "react-router-dom";
    import API from './api_wrapper'
    import { CognitoUser } from 'amazon-cognito-identity-js';
    import { matchPath } from "react-router";
    import { History } from "history";



// -=-=-=-=-=-=-=-=-= No Auth -=-=-=-=-=-=-=-=-=-=-=- 


function NoAuth(props: any) {
    if (props.loggedIn) {
        setTimeout(() => {
            props.history.replace(`/`)
        }, 0);
    }
    return (
        <div className="padded">
            You are not currently signed in to the application.  Please 
            
            <button type="button" className="button default" onClick={signIn}>Sign In</button>
        </div>
    )
}

// -=-=-=-=-=-=-=-=-= Error -=-=-=-=-=-=-=-=-=-=-=- 

interface ErrorDisplayProps {
    error: Error
    dismissError(): any
}

function ErrorDisplay(props: ErrorDisplayProps) {
    var e: any = props.error;
    console.log(e.response);
    return (
        <div className="error">
            <h4>An error has occurred</h4>
            <div>
                {e.toString()}
            </div>
            { e.response && e.response.data ? (
            <pre>
                {JSON.stringify(e.response.data, null, 2)}
            </pre>
            ): undefined }
            <button onClick={props.dismissError}>Dismiss</button>
        </div>
    )
}

// -=-=-=-=-=-=-=-=-= Sign In/Out -=-=-=-=-=-=-=-=-=-=-=- 
function signIn() {
    // There's a bug in the type spec for federatedSignIn that means we can't specify our named SAML provider, so we do
    // a little typecast dance to make the typescript compiler ignore it.
    Auth.federatedSignIn(({
        provider: "Amazon"
    } as any));
}

function signOut() {
    Auth.signOut();
}


interface SignInButtonProps {
    user: CognitoUser|null
}
function SignInOutButton(props: SignInButtonProps) {
    if (props.user) {
        return (
            <button type="button" className="small hollow secondary button" onClick={signOut}>Sign Out</button>
        );
    } else {
        return (
            <button type="button" className="small hollow secondary button" onClick={signIn}>Sign In</button>
        );
    }
}


// -=-=-=-=-=-=-=-=-= Widget List -=-=-=-=-=-=-=-=-=-=-=- 

interface WidgetListProperties {
    widgets: string[]
}

function WidgetList(props: WidgetListProperties) {
    return (
        <div className="padded">
            <h2>Widget List</h2>
            { props.widgets ? (
                <ul>
                { props.widgets.map(widget => (
                    <li key={widget}><Link to={`/widgets/${widget}`}>{widget}</Link></li>
                ))
                }
                </ul>
            ) : (
                <div>No data yet!</div>
            )
            }
        </div>
    );
}


// -=-=-=-=-=-=-=-=-= WidgetView -=-=-=-=-=-=-=-=-=-=-=- 

interface WidgetViewProps {
    widget: Widget|null
    history: History
}

function WidgetView(props: WidgetViewProps) {
    return <div className="padded">
        <h2>Widget Detail</h2>
        <Link to="/widgets">Back to Widget List</Link>
        <div>
            Widget: {props.widget ? props.widget.id: "-"}
        </div>
        <div>
            Description: {props.widget ? props.widget.description : "-"}
        </div>
    </div>
}


// -=-=-=-=-=-=-=-=-= App -=-=-=-=-=-=-=-=-=-=-=- 

interface RouteMatch {
    widgetId: string
}

interface Widget {
    id: string
    description: string
}

interface AppState {
    widgetNames: string[]
    selectedWidgetName: string|undefined
    widgetDetails: Widget|null,
    user: CognitoUser|null,
    isLoading: boolean
    error: Error|null;
}

function compareUsers(user1: CognitoUser|null, user2: CognitoUser|null) {
    if (user1 == null) {
        return user2 === null;
    }; 
    if (user2 == null) {
        return false;
    }
    return user1.getUsername() === user2.getUsername();
}


class App extends Component<RouteComponentProps<RouteMatch>,AppState> {
    constructor(props: RouteComponentProps<RouteMatch>) {
        super(props);
        this.state = {
            widgetNames: [],
            selectedWidgetName: undefined,
            widgetDetails: null,
            isLoading: false,
            error: null,
            user: null
        }
    

        Hub.listen('auth', async (data) => {
            const { payload } = data;
            console.log(`${payload.event} happened`, payload);
            if (payload.event === 'signIn' || payload.event === 'signOut') {
                await this.updateAuth();
            }
        });
        this.dismissError = this.dismissError.bind(this);
    }


    async updateAuth() {
        var user;
        try {
            user = await Auth.currentAuthenticatedUser();
        } catch (e) {
            user = null;
        }
        if (!compareUsers(user, this.state.user)) {
            this.setState({user})
        }
    }

    async componentDidUpdate(prevProps: RouteComponentProps<RouteMatch>, prevState: AppState) {
        console.log("State", this.state);

        await this.updateAuth();


        if (this.state.user && this.state.user !== prevState.user) {
            console.log("Fetching widgets");
            await this.doLoad(this.fetchWidgets(this.props.match.params), { widgetNames: [] });
        }

        if (this.props.location.pathname !== prevProps.location.pathname) {
            console.log(`Path changed from ${prevProps.location.pathname} to ${this.props.location.pathname} `)
            await this.checkSelection();
        }
    }

    async checkSelection() {
        const subMatch = matchPath<RouteMatch>(this.props.location.pathname, { path: "/widgets/:widgetId" });
        const selectedWidgetName = subMatch ? subMatch.params.widgetId : undefined;
        
        if (selectedWidgetName !== this.state.selectedWidgetName)  {
            this.setState({
                selectedWidgetName,
                widgetDetails: null,
            }, async () => {
                if (selectedWidgetName) {
                    await this.doLoad(this.fetchWidgetDetails(selectedWidgetName), { widgetDetails: null });
                }
            });
        }
    }

    /**
     * Wraps an action (async function) with error checking and "in progress" state.
     * @param action 
     * @param blankValues 
     */
    async doLoad(action: Promise<any>, blankValues: any) {
        this.setState({
            ...blankValues,
            isLoading: true,
            // error: null,
        })
        
        try {
            this.setState({
                ...(await action),
                isLoading: false,
                // error: null,
            });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: e
            });
        }
    }

    async fetchWidgetDetails(widgetId: string) {
        return {
            widgetDetails: await API.get(`/widgets/${widgetId}`),
        };
    }

    async fetchWidgets(match: RouteMatch) {
        return {
            widgetNames: (await API.get("/widgets")).widgets
        };
    }

    async logoutClicked() {
        var data = await Auth.signOut();
        console.log(data);
    }


    async componentDidMount() {
        await this.updateAuth();
        await this.checkSelection();
    }

    dismissError() {
        this.setState({
            error: null
        })
    }

    render() {
        return (
        <div className="app">
            <header >
            <Link to="/">
                <h1>Example App</h1>
            </Link>
            <SignInOutButton user={this.state.user}/>
            </header>
            <div className="appbody">
                { this.state.user ? (
                    <div>
                        { this.state.error ? (
                            <ErrorDisplay error={this.state.error} dismissError={this.dismissError}/>
                        ) : undefined}
                        <Switch>
                            <Route path="/widgets/:widgetId" render={() => <WidgetView widget={this.state.widgetDetails}  history={this.props.history}/>}/>
                            <Route path="/widgets" >
                                <WidgetList widgets={this.state.widgetNames}/>
                            </Route>
                            <Route path="/" >
                                <Redirect to="/widgets"/>
                            </Route>
                        </Switch>
                    </div>
                ) : (
                    <NoAuth/>
                )}
            </div>
        </div>
        );
    }
}
export default withRouter(App);
