const host = window.location.host;
var apiEndpoint;

const appPrefix="tmpl"
const apiPrefix=`${appPrefix}api`
const appDomain="wasa.sa.aws.dev"

// allow API endpoint diversity, based on hostname. 
if (process.env.NODE_ENV === "development") {
    console.log(process.env);
    // Development. 
    if (process.env.REACT_API_ENDPOINT) {
        // You can specify your own full endpoint, if you wish
        apiEndpoint = process.env.REACT_API_ENDPOINT;
    } else if (process.env.REACT_APP_BACKEND) {
        // As a shortcut, if you define $REACT_APP_BACKEND We'll substitute that in.
        apiEndpoint = `https://${apiPrefix}-${process.env.REACT_APP_BACKEND}.${appDomain}/`
    } else {
        // Fall back to the development endpoint.
        apiEndpoint = `https://${apiPrefix}-dev.${appDomain}/`
    }
} else {
    // We're on an official host.  Infer the API endpoint from the hostname.
    // Look for blah-(envname).blah in the hostname    
    let match = host.match(/^[^.]+-(\w+)\..*$/);
    if (match) {
        apiEndpoint = `https://${apiPrefix}-${match[1]}.${appDomain}/`
    } else {
        apiEndpoint = `https://${apiPrefix}.${appDomain}/`
    }
}


const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_KNOAgQcKx",
    "aws_user_pools_web_client_id": "18ifbbo82l96a1k5g446vjhf95",
    "oauth": {
        "domain": `auth.${appDomain}`,
        "scope": [ "email", "openid", "profile" ],        
        "redirectSignIn": `${window.location.protocol}//${window.location.host}/`,
        "redirectSignOut": `${window.location.protocol}//${window.location.host}/`,
        "responseType": "token",
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "API": {
        "endpoints": [
            {
                "name": "AppAPI",
                "endpoint": apiEndpoint
            }
        ]
    }
};
export default awsmobile;
